/** @format */

import React, { useEffect, useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import queryString from 'query-string'

import './App.scss'
import SCRoutes from './routes'
import {
  BaseContext,
  BaseContextProvider,
  LogoMode,
  setIntegrateData,
  setAppSize,
} from 'contexts/BaseContext'
import i18next, { currentLanguage, languageLabels, parseLanguage } from 'i18n'
import { getToken } from 'apis/apiService'
import { LanguagePicker } from 'components/SCLanguagePicker'
import { TestType, getTestType } from './utils/hearing'
import { partnerHasTopBar } from './utils/layoutFixes'
import { getLogoExtraStyle, LogoHasExtraMargins } from './utils/partners'
import { sessionStorageKey, saveState } from 'utils/sessionStorage'
import { languagePickerDisabled } from 'utils/navigation'
import tracking from './utils/tracking'

const gaID = process.env.REACT_APP_GA_ID || ''
const fbID = process.env.REACT_APP_FB_PIXEL_ID || ''

tracking.init(gaID, fbID)

const stringParam = (param: string | string[] | null | undefined): string => {
  return (Array.isArray(param) ? param[0] : param) || ''
}

const App = () => {
  const baseContext = useContext(BaseContext)
  const bcDispatch = baseContext.dispatch
  const [lang, setLang] = useState(currentLanguage())
  const [searchParams] = useSearchParams()
  const langParam = parseLanguage(searchParams.get('lang'))

  useEffect(() => {
    tracking.event({
      category: 'App',
      action: 'start',
      label: navigator.userAgent,
    })
  }, [])

  useEffect(() => {
    i18next.on('languageChanged', () => {
      setLang(currentLanguage())
    })
  }, [])

  useEffect(() => {
    if (langParam) {
      i18next.changeLanguage(langParam)
    }
  }, [langParam])

  useEffect(() => {
    const { code } = queryString.parse(window.location.search)
    if (!code) {
      return
    }
    getToken(stringParam(code), `${window.location.origin}/`)
      .then((resp) => {
        if (resp.body && resp.body.access_token) {
          saveState(sessionStorageKey.token, resp.body.access_token)
        }
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    const preventDefault = (e: Event) => {
      e.preventDefault()
    }
    const updateSize = () => {
      const isMobile = window.orientation !== undefined
      const appWidth = isMobile
        ? window.innerWidth
        : document.documentElement.clientWidth
      const appHeight = isMobile
        ? window.innerHeight
        : document.documentElement.clientHeight
      document.documentElement.style.setProperty('--app-width', `${appWidth}px`)
      document.documentElement.style.setProperty(
        '--app-height',
        `${appHeight}px`
      )

      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      bcDispatch(setAppSize(appWidth, appHeight))
    }

    // disable menu (right / long click)
    document.addEventListener('contextmenu', preventDefault, true)
    // update when resize
    window.addEventListener('resize', updateSize, true)
    updateSize()

    // remove listener
    return () => {
      document.removeEventListener('contextmenu', preventDefault, true)
      window.removeEventListener('resize', updateSize, true)
    }
  }, [bcDispatch])

  useEffect(() => {
    // integration
    const { token, backgroundColor, returnUrl, testType } = queryString.parse(
      window.location.search
    )
    bcDispatch(
      setIntegrateData(
        stringParam(token) || '',
        stringParam(backgroundColor) || '',
        stringParam(returnUrl) || '',
        getTestType(stringParam(testType)) || TestType.full
      )
    )
  }, [bcDispatch])

  const render = () => {
    const token = baseContext.integrateToken
    return (
      <div className="App">
        <div
          className="top-bar"
          style={
            partnerHasTopBar(token) && baseContext.logoMode !== LogoMode.none
              ? { display: 'block' }
              : {}
          }></div>
        {baseContext.logoSrc !== '' && (
          <img
            alt=""
            className={classNames('logo', {
              'logo-extra-margin': LogoHasExtraMargins(token),
            })}
            src={baseContext.logoSrc}
            style={getLogoExtraStyle(token)}
          />
        )}
        <LanguagePicker
          hide={languagePickerDisabled(baseContext.currentScreen)}
          selectedLanguage={lang}
          languages={languageLabels}
          handleClick={(lang) => {
            i18next.changeLanguage(lang)
          }}
          compact={baseContext.appSize.width < 470}
        />
        <SCRoutes />
        <div className="footer">&copy; Sonitum 2020 - v 1.0.0</div>
      </div>
    )
  }

  return render()
}

export default BaseContextProvider(App)
