/** @format */

import { PartnerTokens } from 'utils/partners'

export type TestTypes = 'calibration' | 'full' | 'screen'

export const TestType: { [key in TestTypes]: TestTypes } = {
  calibration: 'calibration',
  full: 'full',
  screen: 'screen',
}

export const getTestType = (param: string): TestTypes => {
  switch (param) {
    case 'calibration-ahFeipohzoo8Eewohk0Ki1ad7ohM5Eid':
      return TestType.calibration
    case 'screen':
      return TestType.screen
    default:
      return TestType.full
  }
}

export const ComfortableLevel: {
  [key in LossLevel]: LossLevel
} = {
  unknown: 'unknown',
  moderate: 'moderate',
  severe: 'severe',
  profound: 'profound',
  profoundOrDeaf: 'profoundOrDeaf',
  deaf: 'deaf',
}
const ComfortableLevelArray = [
  'moderate',
  'severe',
  'profound',
  'profoundOrDeaf',
  'deaf',
]

export const ComfortableFrequencies = [500, 1000, 2000]

export const ComfortableVolumes: { [key in LossLevel]: number[] } = {
  unknown: [0, 0, 0],
  moderate: [0, 0, 0],
  severe: [52, 45, 40], //30 dB HL on each F
  profound: [72, 65, 60], //50 dB HL on each F
  profoundOrDeaf: [72, 65, 60], //50 dB HL on each F
  deaf: [0, 0, 0],
}

export type FrequenciesNumber =
  | 250
  | 500
  | 750
  | 1000
  | 1500
  | 2000
  | 3000
  | 4000
  | 6000
  | 8000
type Frequencies =
  | '250'
  | '500'
  | '750'
  | '1000'
  | '1500'
  | '2000'
  | '3000'
  | '4000'
  | '6000'
  | '8000'
const AllFrequencies: FrequenciesNumber[] = [
  250,
  500,
  750,
  1000,
  1500,
  2000,
  3000,
  4000,
  6000,
  8000,
]

export type MeasurementsTypes = 'minimumThreshold' | 'discomfortLevel'

export const MeasurementsType: {
  [key in MeasurementsTypes]: MeasurementsTypes
} = {
  minimumThreshold: 'minimumThreshold',
  discomfortLevel: 'discomfortLevel',
}

export type Measurements = {
  minimumThreshold: MeasurementsResult[]
  discomfortLevel: MeasurementsResult[]
}

const MeasurementsSafetyZone = {
  250: 70,
  500: 75,
  1000: 80,
}

export const getSafeVolume = (frequency: number) => {
  let safeVolume = 70
  Object.entries(MeasurementsSafetyZone).forEach(([key, value]) => {
    const k = parseInt(key, 10)
    if (frequency >= k) {
      safeVolume = value
    }
  })
  return safeVolume
}

export type LossLevel =
  | 'moderate'
  | 'severe'
  | 'profound'
  | 'profoundOrDeaf'
  | 'deaf'
  | 'unknown'

const MeasurementsFrequencies: { [key in LossLevel]: FrequenciesNumber[] } = {
  moderate: [250, 500, 1000, 2000, 4000],
  severe: [250, 500, 1000, 2000, 4000],
  profound: [250, 500, 750, 1000, 1500, 2000, 3000, 4000],
  profoundOrDeaf: [250, 500, 750, 1000, 1500, 2000, 3000, 4000],
  deaf: [],
  unknown: [],
}

const partnerFrequencies: { [key: string]: FrequenciesNumber[] } = {
  [PartnerTokens.Broadleaf]: [1000, 2000, 3000, 4000, 6000, 500, 250],
  [PartnerTokens.EmbraceHearing]: [1000, 2000, 3000, 4000, 6000, 500, 250],
  [PartnerTokens.Hark]: [250, 500, 1000, 2000, 3000, 4000, 6000, 8000],
  [PartnerTokens.TunedCare]: [1000, 2000, 3000, 4000, 6000, 8000, 500, 250],
}

const partnerInteroctavesThreshold: { [key: string]: number } = {
  [PartnerTokens.TunedCare]: 20,
}

const getPartnerFrequencies = (
  currentResults: MeasurementsResult,
  partnerToken: string
): FrequenciesNumber[] => {
  let freqs = partnerFrequencies[partnerToken].slice()
  const threshold = partnerInteroctavesThreshold[partnerToken]
  if (!threshold) {
    return freqs
  }
  if (
    currentResults[500] &&
    currentResults[1000] &&
    Math.abs(currentResults[1000] - currentResults[500]) > threshold
  ) {
    freqs.push(750)
  }
  if (
    currentResults[1000] &&
    currentResults[2000] &&
    Math.abs(currentResults[2000] - currentResults[1000]) > threshold
  ) {
    freqs.push(1500)
  }
  return freqs
}

const CriticalVolumeDifference = (
  comfortableLevel: LossLevel,
  measurementType: MeasurementsTypes
) => {
  switch (comfortableLevel) {
    case 'severe':
      switch (measurementType) {
        case 'minimumThreshold':
          return 15.0
        case 'discomfortLevel':
          return 16.0
        default:
          return null
      }
    default:
      return null
  }
}

type ExternalVolume = 0.5 | 0.75 | 1 | -1

export const HearingExternalVolume: { [key in LossLevel]: ExternalVolume } = {
  moderate: 0.5,
  severe: 0.5, // https://jira.soniccloud.com/browse/WH-15
  profound: 1,
  profoundOrDeaf: 1,
  deaf: -1,
  unknown: -1,
}

export const getFrequenciesToTest = (
  measurementsType: MeasurementsTypes,
  currentFrequency: FrequenciesNumber,
  currentResults: MeasurementsResult,
  comfortableLevelLeft: LossLevel,
  comfortableLevelRight: LossLevel,
  partnerToken: string,
  testType: TestTypes
): FrequenciesNumber[] => {
  if (testType === TestType.calibration) {
    return AllFrequencies
  }
  if (partnerToken && partnerFrequencies[partnerToken]) {
    return getPartnerFrequencies(currentResults, partnerToken)
  }

  const level = getHearingLossLevel(comfortableLevelLeft, comfortableLevelRight)
  const originalFrequencies = MeasurementsFrequencies[level]
  const criticalVolumeDifference = CriticalVolumeDifference(
    level,
    measurementsType
  )
  if (!criticalVolumeDifference) {
    return originalFrequencies
  }
  const frequencyIndex = AllFrequencies.indexOf(currentFrequency)
  let currentFrequencyIndex = 999
  if (frequencyIndex > -1) {
    currentFrequencyIndex = frequencyIndex
  }
  let pairs: FrequenciesNumber[][] = []
  let previousFrequency: FrequenciesNumber
  originalFrequencies.forEach((frequency, index) => {
    if (index > 0) {
      pairs.push([previousFrequency, frequency])
    }
    previousFrequency = frequency
  })
  let resultFrequencies: FrequenciesNumber[] = []
  pairs.forEach((pair) => {
    const firstFrequency = pair[0]
    const secondFrequency = pair[1]
    if (resultFrequencies.length === 0) {
      resultFrequencies.push(firstFrequency)
    }
    resultFrequencies.push(secondFrequency)
    const firstFrequencyIndex = AllFrequencies.indexOf(firstFrequency)
    const secondFrequencyIndex = AllFrequencies.indexOf(secondFrequency)
    if (secondFrequencyIndex - firstFrequencyIndex > 1) {
      const firstVolume = currentResults[firstFrequency]
      const secondVolume = currentResults[secondFrequency]
      if (
        firstVolume !== undefined &&
        secondVolume !== undefined &&
        Math.abs(firstVolume - secondVolume) >= criticalVolumeDifference
      ) {
        const middleFreuencyIndex = secondFrequencyIndex - 1
        if (middleFreuencyIndex <= currentFrequencyIndex) {
          const middleFrequency = AllFrequencies[middleFreuencyIndex]
          resultFrequencies.push(middleFrequency)
        }
      }
    }
  })
  return resultFrequencies
}

export const shouldSkipDiscomfort = (
  partnerToken: string,
  testType: TestTypes,
  comfortableLevelLeft: LossLevel,
  comfortableLevelRight: LossLevel
) => {
  if (testType === TestType.calibration) {
    return true
  }
  return (
    partnerToken === PartnerTokens.Hark ||
    testType === TestType.screen ||
    (comfortableLevelLeft === 'moderate' &&
      comfortableLevelRight === 'moderate')
  )
}

export const getHearingLossLevel = (
  left: LossLevel,
  right: LossLevel
): LossLevel => {
  if (left === 'deaf') {
    return right
  } else if (right === 'deaf') {
    return left
  } else {
    const leftIndex = ComfortableLevelArray.indexOf(left)
    const rightIndex = ComfortableLevelArray.indexOf(right)
    return leftIndex > rightIndex ? left : right
  }
}

// These thresholds represent 30dB HL at each frequenccy assuming a 0.5 volume level
const LossThreshold: { [key in Frequencies]: number } = {
  250: 65,
  500: 52,
  750: 47,
  1000: 45,
  1500: 41,
  2000: 39,
  3000: 42,
  4000: 44,
  6000: 50,
  8000: 51,
}

const SPLAdjustment: { [key in ExternalVolume]: number } = {
  0.5: 0,
  0.75: 14,
  1: 28,
  '-1': 0,
}

export type ComfortableResult = {
  left: LossLevel
  right: LossLevel
}

export type MeasurementsResult = {
  [key in Frequencies]?: number
}

export const lossLevel = (
  comfortableResult: ComfortableResult,
  measurementsResult: MeasurementsResult[]
) => {
  const inititalComfortLevelLeft = HearingExternalVolume[comfortableResult.left]
  const inititalComfortLevelRight =
    HearingExternalVolume[comfortableResult.right]
  const inititalComfortLevel =
    inititalComfortLevelLeft > inititalComfortLevelRight
      ? inititalComfortLevelLeft
      : inititalComfortLevelRight
  const adj = SPLAdjustment[inititalComfortLevel]

  const leftLoss = evaluateMeasurements(adj, measurementsResult[0])
  const rightLoss = evaluateMeasurements(adj, measurementsResult[1])

  //console.log("left: " + lLoss + " right: " + rLoss)

  return leftLoss > rightLoss ? leftLoss : rightLoss
}

const evaluateMeasurements = (
  adj: number,
  measurements: MeasurementsResult
) => {
  let lev = 0
  let key: keyof MeasurementsResult
  for (key in measurements) {
    const min = measurements[key]! + 96
    if (min + adj > LossThreshold[key]) {
      //console.log("R loss at: " + key + " thresh: " + min )
      if (lev === 0) {
        lev = 1
      }
      if (min + adj > LossThreshold[key] + 10) {
        //adding 10 to determine if loss level is 40dB HL i.e. aidable loss
        if (lev === 1) {
          lev = 2
        }
        //console.log("R aidable loss at: " + key + " thresh: " + min )
      }
    } //else console.log("R ok at: " + key + " thresh: " + min )
  }
  return lev
}

type Letter = {
  letter: string
  graphPosition: Point
}

const AudiogramLetter = [
  {
    letter: 'j',
    graphPosition: {
      freq: 176.523274342049,
      volume: 38.1745533107491,
    },
  },
  {
    letter: 'm',
    graphPosition: {
      freq: 247.115063093023,
      volume: 38.1745533107491,
    },
  },
  {
    letter: 'n',
    graphPosition: {
      freq: 289.265653327373,
      volume: 45.6137658628452,
    },
  },
  {
    letter: 'z',
    graphPosition: {
      freq: 301.317158043969,
      volume: 26.6242471127059,
    },
  },
  {
    letter: 'd',
    graphPosition: {
      freq: 349.9728057966,
      volume: 38.3702969956986,
    },
  },
  {
    letter: 'e',
    graphPosition: {
      freq: 379.591383303019,
      volume: 52.8572347299917,
    },
  },
  {
    letter: 'ng',
    graphPosition: {
      freq: 402.25877385609,
      volume: 47.3754590273908,
    },
  },
  {
    letter: 'v',
    graphPosition: {
      freq: 417.858210302611,
      volume: 26.6242471127059,
    },
  },
  {
    letter: 'u',
    graphPosition: {
      freq: 436.285096323713,
      volume: 58.1427904856359,
    },
  },
  {
    letter: 'b',
    graphPosition: {
      freq: 478.710623780188,
      volume: 40.1319901602442,
    },
  },
  {
    letter: 'l',
    graphPosition: {
      freq: 537.59030229882,
      volume: 53.0529784149413,
    },
  },
  {
    letter: 'i',
    graphPosition: {
      freq: 579.474083224371,
      volume: 35.2379217744991,
    },
  },
  {
    letter: 'o',
    graphPosition: {
      freq: 596.749343503266,
      volume: 45.8095095477947,
    },
  },
  {
    letter: 'a',
    graphPosition: {
      freq: 682.409639124263,
      volume: 40.328210107201,
    },
  },
  {
    letter: 'r',
    graphPosition: {
      freq: 845.170294327642,
      volume: 44.8307911230471,
    },
  },
  {
    letter: 'p',
    graphPosition: {
      freq: 1156.02119471499,
      volume: 23.6876155764559,
    },
  },
  {
    letter: 'ch',
    graphPosition: {
      freq: 1169.50917321032,
      volume: 36.0208965142972,
    },
  },
  {
    letter: 'h',
    graphPosition: {
      freq: 1441.06868482366,
      volume: 27.9944529073525,
    },
  },
  {
    letter: 'sh',
    graphPosition: {
      freq: 1734.9623592896,
      volume: 39.5599994896253,
    },
  },
  {
    letter: 'g',
    graphPosition: {
      freq: 1838.56605295274,
      volume: 32.6932538701553,
    },
  },
  {
    letter: 'k',
    graphPosition: {
      freq: 3479.98531877402,
      volume: 25.2540413180592,
    },
  },
  {
    letter: 'f',
    graphPosition: {
      freq: 4338.23915704133,
      volume: 16.0345613831376,
    },
  },
  {
    letter: 's',
    graphPosition: {
      freq: 5407.94634654547,
      volume: 20.9472039871626,
    },
  },
  {
    letter: 'th',
    graphPosition: {
      freq: 6670.79600344698,
      volume: 16.0345613831376,
    },
  },
]

export type Point = {
  freq: number
  volume: number
}

export type Audiogram = {
  points_left: Point[]
  points_right: Point[]
}

export const splitHearLetters = (points: Point[]) => {
  let canHearLetters: Letter[] = []
  AudiogramLetter.forEach((letter) => {
    const pointCount = points.length
    for (let i = 0; i < pointCount; i++) {
      if (letter.graphPosition.freq < points[i].freq) {
        const rightPoint = points[i]
        let leftPoint = undefined
        if (i === 0) {
          leftPoint = { freq: 125.0, volume: rightPoint.volume }
        } else {
          leftPoint = points[i - 1]
        }
        const freqDiff = rightPoint.freq - leftPoint.freq
        const volumeDiff = rightPoint.volume - leftPoint.volume
        const letterVolumeProjection =
          (volumeDiff * (letter.graphPosition.freq - leftPoint.freq)) /
            freqDiff +
          leftPoint.volume
        if (letter.graphPosition.volume > letterVolumeProjection) {
          canHearLetters.push(letter)
        }
        break
      }
    }
  })
  const canHearLetterTextArray = canHearLetters.map((letter) => {
    return letter.letter
  })
  const dontHearLetterTextArray = AudiogramLetter.filter((letter) => {
    return canHearLetterTextArray.indexOf(letter.letter) === -1
  }).map((letter) => {
    return letter.letter
  })
  return { hear: canHearLetterTextArray, dont: dontHearLetterTextArray }
}

export type HearingScore = {
  scoreLeft: number
  scoreRight: number
}

export const getHearingScore = (score: HearingScore) => {
  if (score.scoreLeft === 0) {
    return score.scoreRight
  }
  if (score.scoreRight === 0) {
    return score.scoreLeft
  }
  return (score.scoreLeft + score.scoreRight) / 2
}

const numberFreqs = [500, 1000, 2000, 4000]

const oneEarNumber = (points: Point[]): number => {
  let count = 0
  let num = 0
  points.forEach((p) => {
    if (numberFreqs.indexOf(p.freq) === -1) {
      return
    }
    count += 1
    num += p.volume
  })
  return num / count
}

const hearingNumber = (audiogram: Audiogram): HearingScore => {
  return {
    scoreLeft: Math.max(0, oneEarNumber(audiogram.points_left)),
    scoreRight: Math.max(0, oneEarNumber(audiogram.points_right)),
  }
}

export const hearingNumberImprovement = (hearingNo: number): number => {
  if (hearingNo === 0) {
    return 0
  }
  return Math.max(0, Math.round((100 * (hearingNo - 25)) / hearingNo))
}

export interface ScoreResults {
  left: number
  right: number
  average: number
  improvement: number
}

const averageScore = (scoreLeft: number, scoreRight: number): number => {
  const overallScore = scoreLeft + scoreRight
  if (scoreLeft > 0 && scoreRight > 0) {
    return Math.round(overallScore / 2)
  }
  return overallScore
}

export const hearingScoreResults = (
  useScore: boolean,
  audiogram: Audiogram,
  score: HearingScore
): ScoreResults => {
  const s = useScore ? score : hearingNumber(audiogram)
  const left = Math.round(s.scoreLeft)
  const right = Math.round(s.scoreRight)
  const average = useScore
    ? averageScore(left, right)
    : Math.round((left + right) / 2)
  const improvement = useScore ? 0 : hearingNumberImprovement(average)
  return { left, right, average, improvement }
}
