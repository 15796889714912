/** @format */

import ApiClient, { Response as RawResponse } from 'apis/apiClient'
import type { Language } from 'i18n'
import type { MeasurementsResult, Point } from 'utils/hearing'
import { HearingExternalVolume, getHearingLossLevel } from 'utils/hearing'
import type { Question } from 'utils/questions'

const apiClient = new ApiClient()

export const getAudioGram = (profileId: string) => {
  return apiClient.get(`/hearing_profiles/${profileId}/audiogram/`)
}

type BaseContext = {
  integrateToken: string
  integrateReturnUrl: string
  integrateTestType: string
  queryString: string
}

type HearingResultContext = {
  questionnaire: Question[]
  intent?: Question[]
}

export const createUser = (
  firstName: string,
  lastName: string,
  phoneNumber: string | undefined,
  emailAddress: string,
  password: string | undefined,
  token: string,
  gender: string | undefined,
  age: string | undefined,
  customerId: string | undefined | null,
  preferredLanguage: Language | undefined,
  contactOptIn: boolean,
  baseContext: BaseContext,
  hearingResultContext: HearingResultContext
) => {
  return signUp(
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
    password,
    token,
    customerId,
    preferredLanguage
  )
    .then((resp: RawResponse): Promise<RawResponse> => {
      if (!resp.body || !resp.body.id) {
        throw new Error('creating account: ' + resp.body.message)
      }
      if (password) {
        return signIn(resp.body.email, password)
      }
      return Promise.resolve({
        body: {
          user: resp.body,
          token: resp.headers['x-sc-token'],
        },
      })
    })
    .then((resp) => {
      if (!resp.body || !resp.body.token) {
        throw new Error('logging in: ' + resp.body.message)
      }
      const { user } = resp.body
      let promises = [
        user,
        setMetaData('gender', gender),
        setMetaData('age', age),
        setMetaData('webhat_referrer', {
          token: baseContext.integrateToken,
          return_url: baseContext.integrateReturnUrl,
          test_type: baseContext.integrateTestType,
        }),
        setMetaData('webhat_query_string', baseContext.queryString),
        setMetaData('should_finish_onboarding', false),
        setMetaData('partner_contact_opt_in', contactOptIn),
      ]
      if (
        hearingResultContext.questionnaire &&
        hearingResultContext.questionnaire.length
      ) {
        promises.push(
          setMetaData(
            'webhat_questionnaire',
            hearingResultContext.questionnaire
          )
        )
      }
      if (hearingResultContext.intent && hearingResultContext.intent.length) {
        promises.push(setMetaData('webhat_intent', hearingResultContext.intent))
      }
      return promises
    })
    .then((resps) => {
      const user = resps[0]
      return user
    })
}

type SignUpRequest = {
  first_name: string
  last_name: string
  email: string
  phone?: string
  password?: string
  temp_credentials?: boolean
  partner_token?: string
  partner_id?: string
  preferred_language?: Language
}

const signUp = (
  firstName: string,
  lastName: string,
  phoneNumber: string | undefined,
  email: string,
  password: string | undefined,
  token: string,
  customerId: string | undefined | null,
  preferredLanguage: Language | undefined
) => {
  let body: SignUpRequest = {
    first_name: firstName,
    last_name: lastName,
    email,
  }
  if (phoneNumber) {
    body.phone = phoneNumber
  }
  if (password) {
    body.password = password
  } else {
    body.temp_credentials = true
  }
  if (token) {
    body.partner_token = token
  }
  if (customerId) {
    body.partner_id = customerId
  }
  if (preferredLanguage) {
    body.preferred_language = preferredLanguage
  }
  return apiClient.post('/users/', { body: body })
}

export const resetPassword = (email: string) => {
  return apiClient.post('/password_reset/', {
    body: {
      email,
    },
  })
}

export const signIn = (
  email: string,
  password?: string,
  customerCode?: string
) => {
  return apiClient.post('/auth/login/', {
    body: {
      email,
      password,
      customer_code: customerCode,
    },
  })
}

export const getToken = (code: string, redirectUri: string) => {
  return apiClient.post('/auth/token/', {
    body: {
      grant_type: 'authorization_code',
      code,
      redirect_uri: redirectUri,
    },
  })
}

export const setMetaData = (key: string, value: any) => {
  return apiClient.put(`/user/meta_infos/${key}/`, {
    body: {
      value,
    },
  })
}

export const getUser = () => {
  return apiClient.get('/user/')
}

export const getProfiles = () => {
  return apiClient.get('/user/hearing_profiles/')
}

type ComfortableResults = {
  left: ReturnType<typeof getHearingLossLevel>
  right: ReturnType<typeof getHearingLossLevel>
}

type MeasurementsResults = {
  minimumThreshold: MeasurementsResult[]
  discomfortLevel: MeasurementsResult[]
}

export const saveProfile = (
  id: string,
  name: string,
  comfortableResult: ComfortableResults,
  measurementsResult: MeasurementsResults
) => {
  if (
    !comfortableResult ||
    !comfortableResult.left ||
    !comfortableResult.right
  ) {
    throw new Error('incomplete measurements data (comfortable level)')
  }
  if (
    !measurementsResult ||
    !measurementsResult.minimumThreshold ||
    !measurementsResult.discomfortLevel
  ) {
    throw new Error('incomplete measurements data (threshold/discomfort)')
  }
  const leftLevel = comfortableResult.left
  const rightLevel = comfortableResult.right
  const lossLevel = getHearingLossLevel(leftLevel, rightLevel)
  const leftLossLevel = leftLevel === 'deaf' ? 'deaf' : lossLevel
  const rightLossLevel = rightLevel === 'deaf' ? 'deaf' : lossLevel
  const now = new Date()
  return apiClient.post(`/users/${id}/hearing_profiles/`, {
    body: {
      name: `${name}'s Profile (${now.toISOString().substring(0, 10)})`,
      type: 'MinThreshDiscomfortProfile',
      initial_comfort_level_left: HearingExternalVolume[leftLossLevel],
      initial_comfort_level_right: HearingExternalVolume[rightLossLevel],
      prescreen_comfort_level_left: HearingExternalVolume[leftLevel],
      prescreen_comfort_level_right: HearingExternalVolume[rightLevel],
      min_threshold_frequencies_left: getMeasurementsData(
        measurementsResult.minimumThreshold[0]
      ),
      min_threshold_frequencies_right: getMeasurementsData(
        measurementsResult.minimumThreshold[1]
      ),
      discomfort_frequencies_left: getMeasurementsData(
        measurementsResult.discomfortLevel[0]
      ),
      discomfort_frequencies_right: getMeasurementsData(
        measurementsResult.discomfortLevel[1]
      ),
    },
  })
}

const getMeasurementsData = (result: MeasurementsResult): Point[] => {
  return Object.entries(result).map(([key, value]) => {
    return {
      volume: value! + 96,
      freq: Number(key),
    }
  })
}

export const testables = {
  getMeasurementsData,
}
