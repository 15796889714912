/** @format */

let debug = false

const warn = (s: string) => {
  console.warn('[fb-pixel]', s)
}

const log = (s: string) => {
  console.info('[fb-pixel]', s)
}

const defaultOptions = {
  autoConfig: true,
  debug: false,
}

declare global {
  interface Window {
    fbq: (...args: any[]) => void
  }
}

const internalFbq = (...args: any[]) => {
  if (!window.fbq) {
    warn('Pixel not initialized, call ReactPixel.init first')
    return
  }
  return window.fbq(...args)
}

export type TrackData = {
  content_name: string
}

export type TrackCustomData = {
  label?: string
  value?: number
}

const FbPixel = {
  init(pixelId: string, advancedMatching = {}, options = defaultOptions) {
    /* eslint-disable */
    // @ts-ignore
    !(function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    )
    /* eslint-enable */

    if (!pixelId) {
      warn('Please insert pixel id for initializing')
    } else {
      if (options.autoConfig === false) {
        internalFbq('set', 'autoConfig', false, pixelId)
      }

      internalFbq('init', pixelId, advancedMatching)
      internalFbq('track', 'PageView')

      debug = options.debug
    }
  },

  pageView() {
    internalFbq('track', 'PageView')

    if (debug) {
      log("called fbq('track', 'PageView')")
    }
  },

  track(title: string, data: TrackData) {
    internalFbq('track', title, data)

    if (debug) {
      log(`called fbq('track', '${title}')`)
      log(`with data: ${JSON.stringify(data)}`)
    }
  },

  trackSingle(pixel: string, title: string, data: TrackData) {
    internalFbq('trackSingle', pixel, title, data)

    if (debug) {
      log(`called fbq('trackSingle', '${pixel}', '${title}')`)
      log(`with data: ${JSON.stringify(data)}`)
    }
  },

  trackCustom(event: string, data: TrackCustomData) {
    internalFbq('trackCustom', event, data)

    if (debug) {
      log(`called fbq('trackCustom', '${event}')`)
      log(`with data: ${JSON.stringify(data)}`)
    }
  },

  trackSingleCustom(pixel: string, event: string, data: TrackCustomData) {
    internalFbq('trackSingle', pixel, event, data)

    if (debug) {
      log(`called fbq('trackSingleCustom', '${pixel}', '${event}')`)
      log(`with data: ${JSON.stringify(data)}`)
    }
  },

  grantConsent() {
    internalFbq('consent', 'grant')

    if (debug) {
      log("called fbq('consent', 'grant')")
    }
  },

  revokeConsent() {
    internalFbq('consent', 'revoke')

    if (debug) {
      log("called fbq('consent', 'revoke')")
    }
  },

  fbq(...args: any[]) {
    internalFbq(...args)

    if (debug) {
      log("called fbq('arguments')")
      log(`with arguments: ${JSON.stringify(args)}`)
    }
  },
}

export default FbPixel
