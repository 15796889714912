/** @format */

import ReactGa from 'react-ga'
import FbPixel, { TrackCustomData } from './fbPixel'

type EventData = {
  category: string
  action: string
  label?: string
  value?: number
}

const tracking = {
  init(gaId: string, fbId: string) {
    if (gaId) {
      ReactGa.initialize(gaId)
    }
    if (fbId) {
      FbPixel.init(fbId)
    }
  },

  pageview(title: string) {
    ReactGa.pageview(title)
    FbPixel.track('ViewContent', { content_name: title })
  },

  event(data: EventData) {
    ReactGa.event(data)
    let fbData: TrackCustomData = {}
    if (data.label) {
      fbData.label = data.label
    }
    if (data.value) {
      fbData.value = data.value
    }
    FbPixel.trackCustom(`${data.category}:${data.action}`, fbData)
  },
}

export default tracking
