/** @format */

import React, { useState } from 'react'
import classNames from 'classnames'

import styles from './index.module.scss'
import type { Language } from 'i18n'
import { languageLabels } from 'i18n'
import LanguageIcon from 'static/images/language_icon128px.png'

type Props = {
  hide: boolean
  languages: typeof languageLabels
  selectedLanguage: Language
  handleClick(language: Language): void
  compact: boolean
}

export const LanguagePicker = ({
  hide,
  languages,
  selectedLanguage,
  handleClick,
  compact,
}: Props) => {
  const [showList, setShowList] = useState(false)

  return (
    <div className={classNames(styles.wrap, { [styles.hidden]: hide })}>
      <div className={styles.right}>
        <img src={LanguageIcon} alt="language" className={styles.icon} />{' '}
        <button type="button" onClick={() => setShowList(!showList)}>
          {compact ? selectedLanguage : languages[selectedLanguage]}
        </button>
      </div>
      <ul className={showList ? '' : styles.hidden}>
        {Object.keys(languages).map((lang) => {
          const l = lang as Language
          return (
            <li
              key={lang}
              className={classNames({
                [styles.selected]: l === selectedLanguage,
              })}>
              <button
                type="button"
                onClick={() => {
                  setShowList(false)
                  handleClick(l)
                }}>
                {languages[l]}
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default LanguagePicker
