/** @format */

import Raven from 'raven-js'

export const initSentry = (dsn: string) => {
  if (dsn) {
    Raven.config(dsn, {
      sanitizeKeys: ['password', 'customercode', 'customerCode', 'code'],
    }).install()
  }
}

export const skipNotification = 'skip_notification'

export const notifyException = (e: Error, message?: string) => {
  Raven.captureException(e)
  if (e.message === skipNotification) {
    return
  }
  if (message) {
    // eslint-disable-next-line no-alert
    alert(message)
    return
  }
  // eslint-disable-next-line no-alert
  alert(e)
}
