/** @format */

import superagent from 'superagent'
import { sessionStorageKey, loadState, saveState } from 'utils/sessionStorage'

const formatUrl = (path: string) => {
  const adjustedPath = path[0] !== '/' ? '/' + path : path
  const hpsURL = process.env.REACT_APP_HPS_URL || ''
  const apiVersion = '/v0'
  let hpsVesionedURL = hpsURL + apiVersion
  const baseURL = hpsVesionedURL
  return baseURL + adjustedPath
}

type Method = 'get' | 'post' | 'put' | 'patch' | 'delete'

type Request = {
  params?: object
  body?: object
  header?: { [key: string]: string }
}
export type Response = { headers?: any; body: any; text?: string; err?: any }
type Handler = (path: string, request?: Request) => Promise<Response>

const getHandler = (method: Method): Handler => {
  return (path, { params, body, header } = {}) => {
    // request
    const request = superagent[method](formatUrl(path))
    // params
    if (params) {
      request.query(params)
    }
    // header
    if (header) {
      for (let i in header) {
        request.set(i, header[i])
      }
    }
    // auth
    const key = sessionStorageKey.token
    request.set('Authorization', `Bearer ${loadState(key)}`)
    // body
    if (body) {
      request.send(body)
    }
    // response
    let _promise: Promise<Response> = new Promise((resolve, reject) => {
      request.end((err, resp) => {
        if (err) {
          if (resp?.body?.code === 401) {
            reject(new Error('unauthorized'))
            return
          }
          reject(err)
          return
        }
        const { body, text, headers } = resp
        if (headers && headers['x-sc-token']) {
          saveState(key, headers['x-sc-token'])
        }
        if (body && body.token) {
          saveState(key, body.token)
        }
        resolve({ headers, body, text, err })
      })
    })
    // return
    return _promise
  }
}

export default class ApiClient {
  get: Handler
  post: Handler
  put: Handler
  patch: Handler
  delete: Handler

  constructor() {
    this.get = getHandler('get')
    this.post = getHandler('post')
    this.put = getHandler('put')
    this.patch = getHandler('patch')
    this.delete = getHandler('delete')
  }
  empty() {}
}
