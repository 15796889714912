/** @format */

import harkLogoDark from 'static/logos/Hark_SonicCloud_Lockup_Dark.svg'
import harkLogoLight from 'static/logos/Hark_SonicCloud_Lockup_Light.svg'

type Partners =
  | 'Broadleaf'
  | 'EmbraceHearing'
  | 'Hark'
  | 'HearingTracker'
  | 'TunedCare'

export const PartnerTokens: { [key in Partners]: string } = {
  Broadleaf: '4700e789-d4a6-4269-857f-8aaebb284dbe',
  EmbraceHearing: 'e0c4e5eb-08aa-4ed7-bad7-48c963de6520',
  Hark: '8f1fa42c-d8fb-441c-b652-38970d36726e',
  HearingTracker: '2a0f7e5a-0888-4fa0-bd14-a95a62f20438',
  TunedCare: 'c3194782-c18b-41c2-b6ff-9719cdbd2370',
}

const gcsPrefix =
  'https://storage.googleapis.com/public-assets-ca99248c-9e7c-4907-b623-c9833c3a7689/hat'

const partnerLogos = {
  [PartnerTokens.Hark]: {
    dark: harkLogoDark,
    light: harkLogoLight,
  },
}

const partnerLogoIsSvg: { [key: string]: boolean } = {}

const partnerHasNoLogo = {
  [PartnerTokens.Broadleaf]: true,
}

export const GetLogoSrc = (token: string, isLight: boolean): string => {
  if (!token) {
    return ''
  }
  if (Object.values(PartnerTokens).indexOf(token) === -1) {
    return ''
  }
  if (partnerHasNoLogo[token]) {
    return ''
  }
  const hue = isLight ? 'light' : 'dark'
  if (partnerLogos[token] && partnerLogos[token][hue]) {
    return partnerLogos[token][hue]
  }
  const color = isLight ? 'white' : 'black'
  return partnerLogoIsSvg[token]
    ? `${gcsPrefix}/${token}/logo-${color}.svg`
    : `${gcsPrefix}/${token}/logo-${color}.png`
}

const logoExtraMargins = {
  [PartnerTokens.Hark]: true,
}

export const LogoHasExtraMargins = (token: string) => {
  return !!logoExtraMargins[token]
}

const partnerLogoExtraStyle: {
  [key: string]: { height: string; marginBottom: string }
} = {}

export const getLogoExtraStyle = (token: string) => {
  if (partnerLogoExtraStyle[token]) {
    return partnerLogoExtraStyle[token]
  }
  return {}
}

const skipCreateAccountForm = {
  [PartnerTokens.TunedCare]: true,
}

export const hideCreateAccountForm = (token: string) => {
  return !!skipCreateAccountForm[token]
}

const partnerSupportsCustomerCodeLogin = {
  [PartnerTokens.EmbraceHearing]: true,
  [PartnerTokens.TunedCare]: true,
}

export const supportsCustomerCodeLogin = (token: string) => {
  return !!partnerSupportsCustomerCodeLogin[token]
}

const partnerName: { [key: string]: string } = {}

export const getPartnerName = (token: string): string => {
  return partnerName[token]
}
