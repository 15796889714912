/** @format */

import React from 'react'
import styles from './index.module.scss'

const SCLoading = () => {
  const render = () => {
    return (
      <div
        className={styles.scLoading}
        style={{ width: window.innerWidth, height: window.innerHeight }}>
        <div className="loading">
          <div className="dot" style={{ animationName: 'loadingTopLeft' }} />
          <div className="dot" style={{ animationName: 'loadingTopRight' }} />
          <div className="dot" style={{ animationName: 'loadingBottomLeft' }} />
          <div
            className="dot"
            style={{ animationName: 'loadingBottomRight' }}
          />
        </div>
      </div>
    )
  }
  return render()
}

export default SCLoading
