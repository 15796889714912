import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

export type Language = 'en' | 'ja'

export const languageLabels: { [key in Language]: string } = {
  en: 'English',
  ja: '日本語',
}

export const parseLanguage = (l: string | null): Language | null => {
  if (l == null) {
    return null
  }
  if (l in languageLabels) {
    return l as Language
  }
  return null
}

export const currentLanguage = (): Language => {
  const l = i18n.resolvedLanguage as Language
  if (!languageLabels[l]) {
    return 'en'
  }
  return l
}

i18n
  // load translation using http
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // init i18next
  .init({
    load: 'languageOnly', // we currently don't provide region specific languages
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
