/** @format */

import type { Language } from 'i18n'
import { PartnerTokens } from 'utils/partners'

const findElementWithClass = (elems: HTMLCollection, cls: string) => {
  for (let i = 0; i < elems.length; i++) {
    if (elems[i].classList.contains(cls)) {
      return elems[i]
    }
  }
  return undefined
}

export const scaleCharacterToWindow = (
  names: string[],
  width = 500,
  height = 540
) => {
  names.forEach((name) => {
    const character = document.getElementById(name)
    if (!character) {
      return
    }
    const frame = document.getElementsByClassName('character-frame')[0]
    if (!frame) {
      return
    }
    const factor = 0.8
    const whRatio = width / height
    const sliderWHRatio = 560 / 540
    character.setAttribute(
      'style',
      `height: ${frame.clientHeight * factor}px; width: ${
        frame.clientHeight * factor * whRatio
      }px;`
    )
    const slider = findElementWithClass(
      character.parentElement!.children,
      'main-div'
    )
    if (slider) {
      slider.setAttribute(
        'style',
        `max-width: ${frame.clientHeight * factor * sliderWHRatio + 200}px;`
      )
    }
    const svg = character.getElementsByTagName('svg')[0]
    if (!svg) {
      return
    }
    svg.style.position = 'absolute'
  })
}

const partnerTopBarDisplay = {
  [PartnerTokens.EmbraceHearing]: true,
}

export const partnerHasTopBar = (token: string): boolean => {
  return !!token && partnerTopBarDisplay[token]
}

type PartnerMovieMap = { [key: string]: string }

const PartnerIntroMovie: PartnerMovieMap = {}

const getMovieExtension = (language: Language): string => {
  return language === 'en' ? 'json' : `${language}.json`
}

export const getIntroMoviePath = (
  isPortrait: boolean,
  language: Language,
  token: string
) => {
  let base = 'instruction'
  if (token && PartnerIntroMovie[token]) {
    base = PartnerIntroMovie[token]
  }
  return `animations/lottie/${base}${
    isPortrait ? '_mobile' : ''
  }.${getMovieExtension(language)}`
}

const PartnerSplashMovie: PartnerMovieMap = {
  [PartnerTokens.EmbraceHearing]: 'house_scene_eh',
}

export const getSplashMoviePath = (
  isPortrait: boolean,
  language: Language,
  token: string
) => {
  let base = 'house_scene'
  if (token && PartnerSplashMovie[token] && language === 'en') {
    base = PartnerSplashMovie[token]
  }
  return `animations/lottie/${base}${
    isPortrait ? '_mobile' : ''
  }.${getMovieExtension(language)}`
}

const mobileMaxWidth = 700

export const isMobileLayout = () => {
  return window.innerWidth < mobileMaxWidth
}
