/** @format */

import { Base64 } from 'js-base64'

type Key = 'hearingResultContext' | 'token' | 'hearingScoreContext'

export const sessionStorageKey: { [key in Key]: Key } = {
  hearingResultContext: 'hearingResultContext',
  token: 'token',
  hearingScoreContext: 'hearingScoreContext',
}

const storageKeys: { [key in Key]: string } = {
  hearingResultContext: '9kZSI6ImJs',
  token: '2dvTW9kZSI',
  hearingScoreContext: 'eyJsb2dvTW',
}

export const isUserLoggedIn = (): boolean => {
  return !!loadState(sessionStorageKey.token)
}

export const loadState = (name: Key) => {
  try {
    const storageKey = storageKeys[name]
    const finalEncodedData = sessionStorage.getItem(storageKey)
    if (!finalEncodedData) {
      return undefined
    }
    let firstDecodeData = Base64.decode(finalEncodedData)
    const encodeStorageKey = Base64.encode(storageKey)
    firstDecodeData = firstDecodeData.substring(encodeStorageKey.length - 1)
    const lastDecodeData = Base64.decode(firstDecodeData)
    if (lastDecodeData) {
      return JSON.parse(lastDecodeData)
    } else {
      return undefined
    }
  } catch (err) {
    return undefined
  }
}

export const saveState = (name: Key, state: any) => {
  try {
    const serializedState = JSON.stringify(state)
    const firstEncodedData = Base64.encode(serializedState)
    const storageKey = storageKeys[name]
    const encodeStorageKey = Base64.encode(storageKey)
    const finalEncodedData = Base64.encode(encodeStorageKey + firstEncodedData)
    sessionStorage.setItem(storageKey, finalEncodedData)
  } catch (err) {
    console.log('save local storage error' + err)
  }
}

export const removeState = (name: Key) => {
  const storageKey = storageKeys[name]
  sessionStorage.removeItem(storageKey)
}
