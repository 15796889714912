/** @format */

import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import 'styles/animation.scss'
import { BrowserRouter } from 'react-router-dom'
import { initSentry } from 'utils/notifications'
import App from './App'
import './i18n'

const dsn = process.env.REACT_APP_SENTRY_DSN || ''
initSentry(dsn)

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)
