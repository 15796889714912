/** @format */

import React, { useReducer, useState } from 'react'
import blackLogo from 'static/images/blackLogo.svg'
import whiteLogo from 'static/images/whiteLogo.svg'
import { TestType, TestTypes } from 'utils/hearing'
import { GetLogoSrc, PartnerTokens } from 'utils/partners'
import { NextUrl, Screen, ScreenSet } from 'utils/navigation'

type LogoModes = 'black' | 'white' | 'none'

export const LogoMode: { [key in LogoModes]: LogoModes } = {
  black: 'black',
  white: 'white',
  none: 'none',
}

const initialState = {
  logoMode: LogoMode.black,
  currentScreen: Screen.instruction,
  nextUrl: NextUrl[Screen.instruction],
  logoSrc: '',
  appSize: { width: 0, height: 0 },

  // integration
  integrateToken: '',
  integrateBackgroundColor: '',
  integrateReturnUrl: '',
  integrateTestType: TestType.full,

  queryString: window.location.search,
}

type State = typeof initialState

const partnerTestTypes = {
  [PartnerTokens.Broadleaf]: TestType.screen,
  [PartnerTokens.EmbraceHearing]: TestType.screen,
  [PartnerTokens.TunedCare]: TestType.screen,
}

const getLogoSrc = (mode: LogoModes, token: string) => {
  if (mode === LogoMode.none) {
    return ''
  }
  const src = GetLogoSrc(token, mode === LogoMode.white)
  if (src) {
    return src
  }
  return mode === LogoMode.black ? blackLogo : whiteLogo
}

// this function should be replaced with getNextUrl from utils/navigation
const getNextUrl = (
  scrn: ScreenSet,
  testType: TestTypes,
  queryString: string,
  returnUrl: string
) => {
  if (scrn === Screen.results && testType === TestType.screen && returnUrl) {
    return returnUrl
  }
  return NextUrl[Screen[scrn]] + queryString
}

export const setCurrentScreen = (scrn: ScreenSet) => {
  return {
    type: 'SET_CURRENT_SCREEN',
    screen: scrn,
  } as const
}

export const setLogoMode = (mode: LogoModes) => {
  return {
    type: 'SET_LOGO_MODE',
    logo: mode,
  } as const
}

export const setIntegrateData = (
  token: string = '',
  backgroundColor: string = '',
  returnUrl: string = '',
  testType: TestTypes = TestType.full
) => {
  return {
    type: 'SET_INTEGRATE_DATA',
    integrate: {
      integrateToken: token,
      integrateBackgroundColor: backgroundColor,
      integrateReturnUrl: returnUrl,
      integrateTestType: testType,
    },
  } as const
}

export const setAppSize = (width: number, height: number) => {
  return {
    type: 'SET_APP_SIZE',
    size: { width: width, height: height },
  } as const
}

export const setQueryString = (query: string) => {
  return {
    type: 'SET_QUERY_STRING',
    query,
  } as const
}

type Action = ReturnType<
  | typeof setCurrentScreen
  | typeof setLogoMode
  | typeof setIntegrateData
  | typeof setAppSize
  | typeof setQueryString
>

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_CURRENT_SCREEN':
      if (!Screen[action.screen]) {
        return state
      }
      return {
        ...state,
        currentScreen: Screen[action.screen],
        nextUrl: getNextUrl(
          action.screen,
          state.integrateTestType,
          state.queryString,
          state.integrateReturnUrl
        ),
      }
    case 'SET_LOGO_MODE':
      return {
        ...state,
        logoMode: action.logo,
        logoSrc: getLogoSrc(action.logo, state.integrateToken),
      }
    case 'SET_INTEGRATE_DATA':
      const respData = action.integrate
      let testType = action.integrate.integrateTestType
      if (
        respData.integrateToken &&
        partnerTestTypes[respData.integrateToken]
      ) {
        testType = partnerTestTypes[respData.integrateToken]
      }
      return {
        ...state,
        ...(respData || {}),
        integrateTestType: testType,
        logoSrc: getLogoSrc(state.logoMode, respData.integrateToken),
        nextUrl: getNextUrl(
          state.currentScreen,
          respData.integrateTestType,
          state.queryString,
          respData.integrateReturnUrl
        ),
      }
    case 'SET_APP_SIZE':
      return {
        ...state,
        appSize: action.size,
      }
    case 'SET_QUERY_STRING':
      return {
        ...state,
        queryString: '?' + action.query,
      }
    default:
      throw new Error()
  }
}

type ContextValue = State & {
  dispatch: React.Dispatch<Action>
  screenshots: string[]
  setScreenshots: React.Dispatch<React.SetStateAction<string[]>>
}

export const BaseContext = React.createContext<ContextValue>(null!)

export const BaseContextProvider = (Child: React.FC) => {
  const Provider: React.FC = () => {
    const [screenshots, setScreenshots] = useState<string[]>([])
    const [state, dispatch] = useReducer(reducer, initialState)
    return (
      <BaseContext.Provider
        value={{ ...state, dispatch, screenshots, setScreenshots }}>
        <Child />
      </BaseContext.Provider>
    )
  }
  return Provider
}
