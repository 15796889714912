/** @format */

import queryString from 'query-string'
import { TestType, TestTypes, getHearingScore, HearingScore } from './hearing'
import { PartnerTokens } from './partners'

export type ScreenSet =
  | 'login'
  | 'passwordReset'
  | 'instruction'
  | 'prescreen'
  | 'test'
  | 'questionnaire'
  | 'understandIntent'
  | 'accountCreate'
  | 'accountDone'
  | 'results'
  | 'trySonicCloud'
  | 'continueToEh'
  | 'customerCodeError'

export const Screen: { [key in ScreenSet]: ScreenSet } = {
  login: 'login',
  passwordReset: 'passwordReset',
  instruction: 'instruction',
  prescreen: 'prescreen',
  test: 'test',
  questionnaire: 'questionnaire',
  understandIntent: 'understandIntent',
  accountCreate: 'accountCreate',
  accountDone: 'accountDone',
  results: 'results',
  trySonicCloud: 'trySonicCloud',
  continueToEh: 'continueToEh',
  customerCodeError: 'customerCodeError',
}

export const URLS: Readonly<{ [key in ScreenSet]: string }> = {
  login: '/login',
  passwordReset: '/passwordReset',
  instruction: '/instruction',
  prescreen: '/comfortableListening',
  test: '/measurements',
  questionnaire: '/question',
  understandIntent: '/intent',
  accountCreate: '/createAccount',
  accountDone: '/createAccountDone',
  results: '/result',
  trySonicCloud: '/trySonicCloud',
  continueToEh: '/continueToEh',
  customerCodeError: '/customerCodeError',
}

export const NextUrl: { [key in ScreenSet]: string } = {
  login: '/comfortableListening',
  passwordReset: '/login',
  instruction: '/comfortableListening',
  prescreen: '/measurements',
  test: '/question',
  questionnaire: '/intent',
  understandIntent: '/createAccount',
  accountCreate: '/createAccountDone',
  accountDone: '/result',
  results: '/trySonicCloud',
  trySonicCloud: '',
  continueToEh: '',
  customerCodeError: '',
}

type PartnerMap = { [key: string]: { [key in ScreenSet]?: string } }

const partnerExitPoints: PartnerMap = {
  [PartnerTokens.Hark]: {
    results: 'https://www.harkwellness.com',
  },
  [PartnerTokens.EmbraceHearing]: {
    continueToEh: 'https://embracehearing.com',
  },
}

const partnerShouldEmbedHearingScore: { [key: string]: boolean } = {
  [PartnerTokens.Hark]: true,
}

export const PartnerNextUrl: PartnerMap = {
  [PartnerTokens.EmbraceHearing]: {
    questionnaire: URLS.accountCreate,
    results: URLS.continueToEh,
  },
  [PartnerTokens.Hark]: {
    questionnaire: URLS.results,
  },
  [PartnerTokens.TunedCare]: {
    test: URLS.accountCreate,
  },
}

const getHearingScoreParam = (score: HearingScore) => {
  return { hearing_score: '' + Math.round(getHearingScore(score)) }
}

export const navigateToNextUrl = (
  currentScreen: ScreenSet,
  partnerToken: string,
  testType: TestTypes,
  queryString: string,
  returnUrl: string,
  pushUrl?: (url: string) => void,
  hearingScore?: HearingScore
) => {
  const url = getNextUrl(
    currentScreen,
    partnerToken,
    testType,
    queryString,
    returnUrl,
    hearingScore
  )
  if (!url) {
    return
  }
  if (url.charAt(0) !== '/') {
    NavigateToExternalUrl(url)
    return
  }
  if (pushUrl) {
    pushUrl(url)
    return
  }
}

const sanitizeReturnUrl = (url: string) => {
  if (!url) {
    return ''
  }
  return url.match(/^https?:\/\//) ? url : `https://${url}`
}

export const getNextUrl = (
  currentScreen: ScreenSet,
  partnerToken: string,
  testType: TestTypes,
  query: string,
  returnUrl: string,
  hearingScore?: HearingScore
) => {
  if (
    partnerToken &&
    partnerExitPoints[partnerToken] &&
    partnerExitPoints[partnerToken][currentScreen]
  ) {
    return queryString.stringifyUrl({
      url:
        sanitizeReturnUrl(returnUrl) ||
        partnerExitPoints[partnerToken][currentScreen] ||
        '',
      query:
        hearingScore && partnerShouldEmbedHearingScore[partnerToken]
          ? getHearingScoreParam(hearingScore)
          : {},
    })
  }
  let queryParams = queryString.parse(query)
  delete queryParams.code
  if (
    partnerToken &&
    PartnerNextUrl[partnerToken] &&
    PartnerNextUrl[partnerToken][currentScreen]
  ) {
    return queryString.stringifyUrl({
      url: PartnerNextUrl[partnerToken][currentScreen] || '',
      query: queryParams,
    })
  }
  if (
    currentScreen === Screen.results &&
    testType === TestType.screen &&
    returnUrl
  ) {
    return sanitizeReturnUrl(returnUrl)
  }
  return queryString.stringifyUrl({
    url: NextUrl[Screen[currentScreen]],
    query: queryParams,
  })
}

export const getLoginLink = (next: string, params: { [key: string]: any }) => {
  let p = params
  delete p.scToken
  const ps = queryString.stringify(p)
  const search = ps ? '?' + ps : ''
  return `/login?next=${encodeURIComponent(next + search)}`
}

export const NavigateToExternalUrl = (url: string) => {
  if (url.indexOf('http') !== 0) {
    url = 'http://' + url
  }
  // try...catch because iOS limits us to 100 pushState calls
  try {
    window.history.pushState({}, '', url)
  } catch (error) {
    // They are going to lose state here, but there is no real
    // way to warn them about it since the page will refresh...
    window.location.assign(url)
  }
}

export const testables = {
  getHearingScoreParam,
}

export const languagePickerDisabled = (screen: ScreenSet): boolean => {
  return screen === Screen.instruction || screen === Screen.results
}
