/** @format */

import React, { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import SCLoading from 'components/SCLoading'

const Result = lazy(() => import('./views/Result'))

const SCRoutes = () => {
  return (
    <Suspense fallback={<SCLoading />}>
      <Routes>
        <Route path="/" element={<Result />} />
      </Routes>
    </Suspense>
  )
}

export default SCRoutes
